<template>
  <div>
    <div class="top-nav top-bar">
      <div class="container">
        <div class="row">
          <div class="col-md-10 top-nav-left ">
            <ul style="padding-top: 0px;">
              <li v-if="$getLink() == '/first/'">
                <router-link :to="$getLink() + 'contactUs'"
                  >{{ $t("Contact") }}: 76, Avenue de Luxembourg L-4940
                  Bascharage</router-link
                >
              </li>
              <li v-if="$getLink() == '/second/'">
                <router-link :to="$getLink() + 'contactUs'"
                  >{{ $t("Contact") }}: 109 Rte de Luxembourg, 7241 Bereldange
                  Walferdange</router-link
                >
              </li>

              <li>
                <a href="tel:+352671107278">{{ $t("Phone") }}: +352671107278</a>
              </li>
            </ul>
          </div>
          <div class="col-md-2 top-nav-right">
            <div class="row">
              <!-- <div class="col-8"></div> -->
              <!-- <div class="col-1">
               
              </div> -->

              <div class="col-1">
                <router-link to="faqs">
                  <i class="fa fa-comments-o"></i>
                </router-link>
              </div>
              <div class="col-1">
                <router-link to="allService">
                  <i class="fa fa-calendar-check-o"></i>
                </router-link>
              </div>
              <div class="col-1">
                <router-link to="contactUs">
                  <i class="fa fa-volume-control-phone"></i>
                </router-link>
              </div>
            </div>
            <!-- <ul style="padding-top: 0px;">
              <li>
                <a @click="changeLang('de')">
                  <img src="/image/flags/de.png" style="width: 28px;" />
                </a>
              </li>

              <li>
                <a @click="changeLang('it')">
                  <img src="/image/flags/it.png" style="width: 28px;" />
                </a>
              </li>
              <li>
                <a @click="changeLang('lb')">
                  <img src="/image/flags/lb.png" style="width: 28px;" />
                </a>
              </li>

              <li>
                <a @click="changeLang('pt')">
                  <img src="/image/flags/pt.png" style="width: 28px;" />
                </a>
              </li>

              <li>
                <a @click="changeLang('fr')">
                  <img src="/image/flags/fr.png" style="width: 28px;" />
                </a>
              </li>

              <li>
                <a @click="changeLang('en')">
                  <img src="/image/flags/en.png" style="width: 28px;" />
                </a>
              </li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
    <div class="top-nav top-bar-mob" :class="isTopM ? 'top-bar-mob-fixed' : ''">
      <div :class="$getLink() == '/second/' ? 'branch2' : ''">
        <div class="row">
          <div class="col-2">
            <i class="fa fa-bars" v-b-toggle.sidebar-1></i>

            <b-sidebar id="sidebar-1" title="" shadow>
              <div class="px-3 py-2">
                <ul>
                  <li>
                    <router-link :to="$getLink()" class="course-overlay">
                      {{ $t("Home") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="$getLink() + 'team'"
                      class="course-overlay"
                    >
                      {{ $t("Our team") }}</router-link
                    >
                  </li>
                </ul>
                <!-- <ul>
                 
                </ul> -->
                <h3>{{ $t("Services") }}</h3>
                <ul>
                  <li v-for="item of services" :key="item.id">
                    <a
                      href="https://salonkee.lu/salon/egypta-massage-bereldange?lang=fr"
                      target="_blank"
                      v-if="
                        $getLink() == '/second/' &&
                          (item.id == 12 || item.id == 13)
                      "
                      class="course-overlay"
                    >
                      {{ $getByLang(item.name) }}</a
                    >
                    <router-link
                      v-else
                      :to="$getLink() + 'service/' + item.slug"
                      class="course-overlay"
                    >
                      {{ $getByLang(item.name) }}</router-link
                    >
                  </li>

                  <li>
                    <router-link
                      :to="$getLink() + 'page/gifts'"
                      class="course-overlay"
                    >
                      {{ $t("Gifts") }}</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="/packages" class="course-overlay">
                      {{ $t('PACKAGES') }}</router-link
                    >
                  </li> -->
                </ul>

                <hr />
                <h3>{{ $t("Links") }}</h3>
                <ul>
                  <li>
                    <router-link
                      :to="$getLink() + 'gallery'"
                      class="course-overlay"
                    >
                      {{ $t("gallery") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="$getLink() + 'blog'"
                      class="course-overlay"
                    >
                      {{ $t("blog") }}</router-link
                    >
                  </li>

                  <li v-for="item of list" :key="item.id">
                    <router-link
                      :to="$getLink() + 'page/' + item.slug"
                      class="course-overlay"
                    >
                      {{ $getByLang(item.name) }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="$getLink() + 'faqs'"
                      class="course-overlay"
                    >
                      {{ $t("Faqs") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="$getLink() + 'page/terms'"
                      class="course-overlay"
                    >
                      {{ $t("Terms and Conditions") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="$getLink() + 'page/PrivacyPolicy'"
                      class="course-overlay"
                    >
                      {{ $t("PrivacyPolicy") }}</router-link
                    >
                  </li>

                  <li>
                    <router-link
                      :to="$getLink() + 'contactUs'"
                      class="course-overlay"
                    >
                      {{ $t("Contact us") }}</router-link
                    >
                  </li>
                </ul>
              </div>
            </b-sidebar>
          </div>
          <div class="col-2">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              class="xxxx"
              style="z-index: 999;"
            >
              <template #button-content>
                <img :src="`/image/flags/${lang}.png`" style="width: 28px;" />
              </template>
              <b-dropdown-item @click="changeLang('de')">
                <img src="/image/flags/de.png" style="width: 28px;" /> Deutsche
              </b-dropdown-item>
              <b-dropdown-item @click="changeLang('it')">
                <img src="/image/flags/it.png" style="width: 28px;" /> italiano
              </b-dropdown-item>
              <b-dropdown-item @click="changeLang('lb')">
                <img src="/image/flags/lb.png" style="width: 28px;" />
                Lëtzebuergesch
              </b-dropdown-item>

              <b-dropdown-item @click="changeLang('pt')">
                <img src="/image/flags/pt.png" style="width: 28px;" />
                português
              </b-dropdown-item>

              <b-dropdown-item @click="changeLang('fr')">
                <img src="/image/flags/fr.png" style="width: 28px;" /> français
              </b-dropdown-item>

              <b-dropdown-item @click="changeLang('en')">
                <img src="/image/flags/en.png" style="width: 28px;" />
                English
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-2">
            <router-link :to="$getLink() + 'google-map'">
              <i class="fa fa-map-marker"></i>
            </router-link>
          </div>
          <div class="col-2">
            <router-link :to="$getLink() + 'faqs'">
              <i class="fa fa-comments-o"></i>
            </router-link>
          </div>
          <div class="col-2">
            <router-link :to="$getLink() + 'allService'">
              <i class="fa fa-calendar-check-o"></i>
            </router-link>
          </div>
          <div class="col-2" style="border-right: none;">
            <a href="tel:+352671107278">
              <i class="fa fa-volume-control-phone"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!--   -->
    <b-navbar
      toggleable="lg"
      type="dark"
      :class="$getLink() == '/second/' ? 'branch2' : ''"
      :fixed="isTop"
      variant="dark"
    >
      <b-navbar-brand to="/">
        <img
          class="img-fluid logo-img"
          src="/image/logo.png"
          style="-webkit-transform: scale(1);
            transform: scale(1); 
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;"
        />
      </b-navbar-brand>
      <!-- <b-navbar-toggle target="nav-collapse" > -->
      <b-dropdown
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        style="z-index: 99999999;margin-left: 10%;"
        class="xmnulang"
      >
        <template #button-content>
          <img :src="`/image/flags/${lang}.png`" style="width: 18px;" />
        </template>
        <b-dropdown-item @click="changeLang('de')">
          <img src="/image/flags/de.png" style="width: 28px;" />
          Deutsche
        </b-dropdown-item>
        <b-dropdown-item @click="changeLang('it')">
          <img src="/image/flags/it.png" style="width: 28px;" />
          italiano
        </b-dropdown-item>
        <b-dropdown-item @click="changeLang('lb')">
          <img src="/image/flags/lb.png" style="width: 28px;" />
          Lëtzebuergesch
        </b-dropdown-item>

        <b-dropdown-item @click="changeLang('pt')">
          <img src="/image/flags/pt.png" style="width: 28px;" />
          português
        </b-dropdown-item>

        <b-dropdown-item @click="changeLang('fr')">
          <img src="/image/flags/fr.png" style="width: 28px;" />
          français
        </b-dropdown-item>

        <b-dropdown-item @click="changeLang('en')">
          <img src="/image/flags/en.png" style="width: 28px;" />
          English
        </b-dropdown-item>
      </b-dropdown>
      <!-- </b-navbar-toggle> -->

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="$getLink()" class="active">{{
            $t("Home")
          }}</b-nav-item>
          <b-nav-item-dropdown class="xmenu" :text="$t('Services')" right>
            <div class="row">
              <div class="col-3">
                <img src="../assets/1-ser.jpg" style="width: 100%;" alt="" />
              </div>
              <div class="col-3">
                {{ $t("aboutus") }}
              </div>
              <div class="col-3">
                <ul>
                  <li v-for="item of services" :key="item.id">
                    <a
                      href="https://salonkee.lu/salon/egypta-massage-bereldange?lang=fr"
                      target="_blank"
                      v-if="item.id == 12 || item.id == 13"
                      class="course-overlay"
                    >
                      {{ $getByLang(item.name) }}</a
                    >
                    <router-link
                      v-else
                      :to="$getLink() + 'service/' + item.slug"
                      class="course-overlay"
                    >
                      {{ $getByLang(item.name) }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="$getLink() + 'page/gifts'"
                      class="course-overlay"
                    >
                      {{ $t("Gift Voucher") }}</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="col-3">
                <!-- <ul>
                  <li>
                    <a> {{ $t('PACKAGES') }}</a>
                  </li>
                  <li v-for="item of packakes" :key="item.id">
                    <router-link
                      :to="$getLink() + 'packages/' + item.slug"
                      class="course-overlay"
                    >
                      {{ $getByLang(item.name) }}</router-link
                    >
                  </li>
                </ul> -->
              </div>
            </div>
          </b-nav-item-dropdown>
          <!-- <b-nav-item href="#">GIFT VOUCHERS</b-nav-item> -->

          <b-nav-item
            v-for="item of list"
            :key="item.id"
            :to="$getLink() + 'page/' + item.slug"
            >{{ $getByLang(item.name) }}</b-nav-item
          >
          <b-nav-item :to="$getLink() + 'faqs'">{{ $t("Faqs") }}</b-nav-item>
          <b-nav-item :to="$getLink() + 'gallery'">{{
            $t("gallery")
          }}</b-nav-item>
          <b-nav-item :to="$getLink() + 'blog'">{{ $t("blog") }}</b-nav-item>

          <b-nav-item :to="$getLink() + 'google-map'" class="join">
            {{ $t("Google Map") }}
          </b-nav-item>

          <b-nav-item :to="$getLink() + 'contactUs'" class="login">
            {{ $t("Contact us") }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="icon-float">
      <ul>
        <li>
          <a
            href="https://twitter.com/EgyptaMassage?s=09"
            class="tw1"
            target="_blank"
            ><i class="fa fa-twitter" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a
            href="https://instagram.com/egypta_massage?igshid=83pfg7lqo1cl"
            class="sh1"
            target="_blank"
            ><i class="fa fa-instagram" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/egyptamassage/"
            class="fb1"
            target="_blank"
            ><i class="fa fa-facebook" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/channel/UCspYFFphGmeaK7fXvDbgU-Q"
            target="_blank"
            style="background-color:red;"
            ><i class="fa fa-youtube" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/egypta-massage-a805491a8"
            target="_blank"
            class="li1"
            ><i class="fa fa-linkedin" aria-hidden="true"></i
          ></a>
        </li>
        <li>
          <a
            href="whatsapp://send?phone=+352671107278"
            target="_blank"
            class="wa1"
            ><i class="fa fa-whatsapp" aria-hidden="true"></i
          ></a>
        </li>
        <!--<li><a href="#" class="sh1"><i class="fa fa-envelope-o" aria-hidden="true"></i></a> </li>-->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-header",
  data() {
    const lang = localStorage.lang || "en";
    return {
      list: [],
      services: [],
      packakes: [],
      isTop: null,
      isTopM: null,
      lang,
    };
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    if (this.$getLink() == "/first/") {
      this.$http.get(`page?typePage=header`).then(
        (res) => {
          this.list = res.data;
        },
        (err) => {
          console.log(err);
        }
      );

      this.$http.get("services").then(
        (res) => {
          this.services = res.data;
        },
        (err) => {
          console.log(err);
        }
      );

      this.$http.get("packages").then(
        (res) => {
          this.packakes = res.data;
        },
        (err) => {
          console.log(err);
        }
      );
    }

    if (this.$getLink() == "/second/") {
      this.$http2.get(`page?typePage=header`).then(
        (res) => {
          this.list = res.data;
        },
        (err) => {
          console.log(err);
        }
      );

      this.$http2.get("services").then(
        (res) => {
          this.services = res.data;
        },
        (err) => {
          console.log(err);
        }
      );

      this.$http2.get("packages").then(
        (res) => {
          this.packakes = res.data;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
  methods: {
    changeLang(val) {
      localStorage.lang = val;
      this.$root.$i18n.locale = val;
      this.lang = val;
    },
    handleScroll(event) {
      if (window.innerWidth > 890) {
        if (window.scrollY > 890) {
          this.isTop = "top";
        } else {
          this.isTop = null;
        }
      }

      if (window.innerWidth < 890) {
        if (window.scrollY > 890) {
          this.isTopM = "top";
        } else {
          this.isTopM = null;
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.xmenu .dropdown-menu {
  position: absolute;
  width: 86vw;
  left: -50vw;
  box-shadow: 0px 7px 12px -4px rgba(0, 0, 0, 0.45);
  background: url("/image/mm-col-bg.png") no-repeat right bottom, #fff;
}
.xmenu .row {
  margin: 0;
  padding: 20px 10px;
}

.xmenu .row p {
  font-size: 12px;
  color: #203245;
}
.cor-con-mid p,
li {
  color: #8b4513;
  line-height: 24px;
  font-size: 15px;
  font-weight: 400;
}
.xmenu ul li {
  display: block;
  width: 100%;
  position: relative;
  padding-left: 15px;
}
.xmenu ul li a {
  font-size: 14px;
  color: #203245;
  line-height: 31px;
  padding: 5px;
  text-transform: capitalize;
}

.xmenu ul li::before {
  content: "\f105";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #000;
  font-size: 16px;
  padding-right: 0.5em;
  position: absolute;
  top: 5px;
  left: 0;
}
.xmenu ul li a {
  color: #fff;
  padding: 20px 10px;
  line-height: 60px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
.xmenu ul li a {
  font-size: 14px;
  color: #203245;
  line-height: 31px;
  padding: 5px;
  text-transform: capitalize;
}
.xmenu ul li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 12px;
  left: 0;
  color: #e66030;
  background-color: #e66030;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.15s ease-in;
  z-index: 1;
}

.xmenu ul li a:after {
  bottom: 0px;
}

@media screen and (min-width: 890px) {
  .top-bar {
    display: block;
  }
  .navbar {
    display: flex;
    text-align: center;
  }
  .logo-img {
    height: 50px !important;
  }
  .top-bar-mob {
    display: none;
  }
  .top-bar i {
    color: #fff;
    font-size: 18px;
  }
  .top-bar .col-1 {
    padding: 0.375rem 0.75rem;
  }
  .top-bar button {
    padding: 0;
  }
}

@media screen and (max-width: 890px) {
  .top-bar {
    display: none;
  }
  .navbar {
    display: block;
    text-align: center;
  }
  .bg-dark {
    min-height: 72px !important;
  }

  .navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .logo-img {
    height: 57px !important;
  }
  .navbar-brand {
    margin: auto !important;
  }
  .top-bar-mob {
    display: block;
    height: 51px;
    background-color: #81ecec !important;
    border-bottom: 1px solid #985b2a;
    z-index: 9999999;
    position: relative;
  }
  .bg-dark {
    z-index: auto !important;
  }
  .top-bar-mob-fixed {
    height: 51px;
    position: fixed;
    z-index: 99999999999999999999999;
    width: 100%;
  }
  .top-bar-mob .col-2 {
    font-size: 30px;
    color: #863902 !important;
    border-right: 1px solid #985b2a;
    text-align: center;
  }

  .top-bar-mob i {
    font-size: 30px;
    color: #863902;
  }

  .b-sidebar {
    text-align: left !important;
  }
  .b-sidebar a {
    color: #8f5225 !important;
  }
}

/*-------------------------------------------------*/
.icon-float {
  position: fixed;
  /* left: 0px;
     */
  top: 35%;
  z-index: 999;
  right: 0px;
}
.icon-float ul {
  padding: 0px;
}
.icon-float ul li {
  list-style-type: none;
}
.icon-float ul li a {
  display: block;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 0px;
}
.icon-float ul li a i {
  padding: 8px;
  width: 40px;
  height: 32px;
  text-align: center;
  color: #fff;
}
.icon-float ul li a:hover {
  transform: rotateY(-180deg);
}
.icon-float ul li a:hover i {
  transform: rotateY(180deg);
}
.fb1 {
  background: #3b5998;
}
.gp1 {
  background: #dd4b39;
}
.tw1 {
  background: #1da1f2;
}
.li1 {
  background: #0077b5;
}
.yt1 {
  background: #cd201f;
}
.wa1 {
  background: #34af23;
}
.sh {
  background: #fff;
  padding: 10px 0px;
  font-size: 11px;
  text-align: center;
  color: #333;
  /* border-top-left-radius: 5px; */
}
.sh1 {
  background: #333;
  /* border-bottom-left-radius: 5px; */
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  box-shadow: 0px 7px 12px -4px rgba(0, 0, 0, 0.45);
  background: url("../assets/mm-col-bg.png") no-repeat right bottom, #fff;
}
</style>
