<template>
  <div id="app" style="overflow-x: hidden;">
    <div id="loading-area" v-if="loading"></div>
    <app-header></app-header>

    <router-view style="overflow-x: hidden;" />

    <app-footer style="overflow-x: hidden;"></app-footer>
  </div>
</template>

<script>
import AppHeader from '@/layout/header.vue';
import AppFooter from '@/layout/footer.vue';
export default {
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    // this.loading
    this.$eventHub.$on('showSpinner', (val) => {
      this.loading = val;
    });
    // this.$eventHub.$emit('showSpinner', true);
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 3500);
  },
};
</script>

<style>
#loading-area {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 9999;
  background-image: url(../assets/loading.svg);
  background-repeat: no-repeat;
  background-size: 20vw;
  background-position: center;
}
</style>
