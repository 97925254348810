<template>
  <footer  :class="$getLink() == '/second/' ? 'branch2' : ''">
    <div class="footer container">
      <div class="row">
        <div class="col-md-3 col-xs-12 footer-left fborder">
          <div class="widget">
            <h5 class="widget_title">{{ this.$t('Links') }}</h5>

            <ul>
              <li v-for="item of list" :key="item.id">
                <router-link :to="$getLink() + 'page/' + item.slug">{{
                  $getByLang(item.name)
                }}</router-link>
              </li>
              <li>
                <router-link :to="$getLink() + 'faqs'" class="course-overlay">
                  {{ $t('Faqs') }}</router-link
                >
              </li>
              <li>
                <router-link :to="$getLink() + 'blog'" class="course-overlay">
                  {{ $t('blog') }}</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 col-xs-12 fborder">
          <div class="widget">
            <div class="logo-widget">
              <div class="logo">
                <router-link :to="$getLink()" class="course-overlay">
                  <img
                    src="../assets/logo.png"
                    style="height: 100%; width: 100%;"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4  col-xs-12 footer-right fborder">
          <div class="widget store-btns">
            <h5 class="widget_title">{{ $t('CONTACT INFO') }}:</h5>
            <ul>
              <li>
                <a
                  href="tel://+352671107278"
                  class="contact-icon"
                  style="font-family: initial;"
                  >{{ $t('Phone') }}: +352671107278</a
                >
              </li>

              <li>
                <a href="mailto:egypta.massage@gmail.com" class="contact-icon"
                  >{{ $t('Email') }}: egypta.massage@gmail.com</a
                >
              </li>
            </ul>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.egyptamassage.app"
                class="google-store"
              >
                <img src="../assets/google-store.svg" alt="" />
              </a>
              <a href="#" class="app-store">
                <img src="../assets/app-store.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p class="text-center" v-if="$getLink() == '/first/'">
        Near us
        <br />

        CACTUS BASCHARAGE 250 Metres <br />
        BOFFERDING BASCHARAGE 300 Meters
        <br />
        ÉCOLE PRIMAIRE OP ACKER 50 Meters
        <br />
        KULTURHAUS KÄERJENGER TREFF 50 Meters
      </p>
      <p class="text-center" v-if="$getLink() == '/second/'">
        Near us
        <br />

        Cactus Bereldange 100 meters <br />
        Basic-fit Bereldange 50 meters <br />
        Crav Maga Bereldange 50 meters <br />
        Aral Bereldange 10 meters
      </p>
    </div>
    <div class="footer2 container">
      <div class="row">
        <!-- <div class="col-md-4"></div> -->
        <div class="col-md-4">
          <p style="font-size: 14px; text-transform: uppercase;">
            <a href="https://alwanlab.com" target="_blank">AlwanLab</a> © 2021.
            All Rights Reserved.
          </p>
        </div>
        <div class="col-md-8">
          <div class="socials-widget" style="padding-top: 0;">
            <div class="socials_wrap">
              <a target="_blank" href="https://www.facebook.com/egyptamassage/">
                <span style="background-color:#3b5998;" class="social_icon"
                  ><i class="fa fa-facebook"></i>
                </span>
              </a>
              <a target="_blank" href="https://twitter.com/EgyptaMassage">
                <span style="background-color:#1da1f2;" class="social_icon"
                  ><i class="fa fa-twitter"></i>
                </span>
              </a>
              <a target="_blank" href="https://www.instagram.com/egypta_massage"
                ><span style="background-color:#0077b5;" class="social_icon"
                  ><i class="fa fa-instagram"></i></span
              ></a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCspYFFphGmeaK7fXvDbgU-Q"
              >
                <span style="background-color:#cd201f;" class="social_icon"
                  ><i class="fa fa-youtube"></i>
                </span>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/in/egypta-massage-a805491a8/"
              >
                <span style="background-color:#0077b5;" class="social_icon"
                  ><i class="fa fa-linkedin"></i>
                </span>
              </a>
              <a target="_blank" href="whatsapp://send?phone=+352671107278"
                ><span style="background-color:#34af23;" class="social_icon"
                  ><i class="fa fa-whatsapp"></i></span
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.$http.get(`page?typePage=footer`).then(
      (res) => {
        this.list = res.data;
      },
      (err) => {
        console.log(err);
      },
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
